@media print {
    @page {
        margin: 0;
    }
    body {
        margin: 1cm;
        box-shadow: none;
        background-color: #fff;
    }
    header,
    footer {
        display: none;
    }
    .print-hide-field {
        display: none;
    }
    .print-mt-0 {
        margin-top: 0 !important;
    }
    .pagebreak {
        page-break-before: always;
        margin-bottom: 40px;
    }
}
